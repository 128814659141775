import React from 'react';
import cn from 'classnames';
import GenericSlider, { SliderProps, SliderTooltip, HandleProps } from 'rc-slider';
import 'rc-slider/assets/index.css';
import css from './styles.module.scss';

export interface Props extends SliderProps {
    className?: string;
}

const handle: React.FC<HandleProps & { index?: number }> = (props) => {
    const { value, index, ...restProps } = props;
    return (
        <SliderTooltip prefixCls="rc-slider-tooltip" overlay={`${value}`} placement="top" key={index} visible>
            <GenericSlider.Handle value={value} {...restProps} />
        </SliderTooltip>
    );
};

const Slider: React.FC<Props> = ({ className, ...rest }) => {
    return (
        <div className={cn(css.slider, className)}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            <GenericSlider handle={handle} {...rest} />

            <div className={css.minMax}>
                {rest.min && <div className={css.minMaxValue}>{rest.min}</div>}
                {rest.max && <div className={css.minMaxValue}>{rest.max}</div>}
            </div>
        </div>
    );
};

export default Slider;
