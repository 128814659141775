import React, { useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Selfie, { Props as SelfieProps } from 'components/Selfie';
import * as routes from 'routes';
import { ReactComponent as SignUpIcon } from 'assets/icons/signup.svg';
import Page from 'components/Page';
import Header from 'components/Header';
import Button from 'components/Button';
import AdaptiveIcons from 'components/AdaptiveIcons';
import { ReactComponent as BulletIcon } from 'assets/icons/bullet.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/repeat.svg';
import { readBlobAsByteArray } from 'libs';
import RestAPI from 'libs/RestAPI';
import css from './styles.module.scss';
import useAppLoader from 'libs/useAppLoader';
import { getServerErrorMessage, SERVER_ERROR_TYPE, ServerErrorMessage } from 'libs/serverErrorMessages';
import isServerErrorWithCode from 'libs/isServerErrorWithCode';

type Stage = 'photo' | 'success' | 'error';

const Identification: React.FC = () => {
    const [stage, setStage] = useState<Stage>('photo');
    const [userName, setUserName] = useState<string>('');
    const [errorType, setErrorType] = useState<SERVER_ERROR_TYPE>(SERVER_ERROR_TYPE.UNKNOWN_ERROR);
    const { push } = useHistory();
    const [, setAppLoader] = useAppLoader();

    const toMain = () => push(routes.main());
    const toPhoto = () => setStage('photo');

    const toErrorStage = (errorType: SERVER_ERROR_TYPE) => {
        setErrorType(errorType);
        setStage('error');
    };

    const successVideo = async (video: Blob | null) => {
        if (video) {
            try {
                setAppLoader(true);
                const file = await readBlobAsByteArray(video);
                const result = await RestAPI.identification(file);
                setAppLoader(false);

                if (result.code === 'LISuccess') {
                    setUserName(result.dossier.name);
                    setStage('success');
                } else {
                    if (result.code === 'LINotFound') return toErrorStage(SERVER_ERROR_TYPE.PROFILE_NOT_FOUND);

                    if (result.code === 'LIBadVideo') {
                        const errorMessage =
                            SERVER_ERROR_TYPE[result.message as keyof typeof SERVER_ERROR_TYPE] ??
                            SERVER_ERROR_TYPE.UNKNOWN_ERROR;
                        return toErrorStage(errorMessage);
                    }

                    toErrorStage(SERVER_ERROR_TYPE.UNKNOWN_ERROR);
                }
            } catch (e) {
                setAppLoader(false);

                if (isServerErrorWithCode(e)) {
                    if (e.code == 'network_connection')
                        return toErrorStage(SERVER_ERROR_TYPE.NETWORK_ERROR)

                    if (e.code == 'connection_timeout')
                        return toErrorStage(SERVER_ERROR_TYPE.TIMEOUT_ERROR)
                }

                toErrorStage(SERVER_ERROR_TYPE.UNKNOWN_ERROR);
            }
        } else toErrorStage(SERVER_ERROR_TYPE.UNKNOWN_ERROR);
    };

    if (stage === 'error')
        return <Error backClick={toPhoto} repeatAgainClick={toPhoto} errorMessage={getServerErrorMessage(errorType)} />;
    if (stage === 'success') return <Success backClick={toMain} okClick={toMain} userName={userName} />;

    return <Photo back={toMain} complete={successVideo} />;
};

type PhotoProps = Omit<SelfieProps, 'buttonTitle' | 'children'>;

const Photo: React.FC<PhotoProps> = ({ ...rest }) => {
    const { t } = useTranslation();

    return (
        <Selfie title={t('identification')} buttonTitle={t('findProfile')} buttonIcon={<SignUpIcon />} {...rest}>
            <div
                className={cn(css.helpText, 'text-normal')}
                dangerouslySetInnerHTML={{ __html: t('searchUserProfileBySelfie') }}
            />
        </Selfie>
    );
};

type ErrorProps = {
    backClick: () => void;
    repeatAgainClick: () => void;
    errorMessage: ServerErrorMessage;
};

const Error: React.FC<ErrorProps> = ({ backClick, repeatAgainClick, errorMessage: { title, points } }) => {
    const { t } = useTranslation();

    return (
        <Page type="error">
            <Header backClick={backClick} title={t('identification')} />

            <Page.Content className={css.errorContent}>
                <Page.Icon>
                    <AdaptiveIcons name="cross" />
                </Page.Icon>

                <Page.Title dangerouslySetInnerHTML={{ __html: title }} />

                <Page.List className={css.possibleList} title={t('itCanBe')}>
                    {points.map((point, index) => (
                        <Page.ListItem bulletIcon={<BulletIcon />} key={index}>
                            {point}
                        </Page.ListItem>
                    ))}
                </Page.List>

                <Page.Footer>
                    <Button icon={<RepeatIcon />} appearance="white-fill" onClick={repeatAgainClick}>
                        {t('repeatAgain')}
                    </Button>
                </Page.Footer>
            </Page.Content>
        </Page>
    );
};

interface SuccessProps {
    backClick: () => void;
    okClick: () => void;
    userName: string;
}

const Success: React.FC<SuccessProps> = ({ backClick, okClick, userName }) => {
    const { t } = useTranslation();

    return (
        <Page type="success">
            <Header backClick={backClick} title={t('identification')} />

            <Page.Content className={css.successContent}>
                <Page.Icon>
                    <AdaptiveIcons name="mark" />
                </Page.Icon>

                <h3 className={css.successTitle}>{t('identificationSuccess')}</h3>

                <div className={css.successMessage}>
                    {t('hello')},<div className={css.userName}>{userName}</div>
                    {t('weFoundYourProfile')}
                </div>

                <Button onClick={okClick} className={css.successButton} appearance="white-fill">
                    OK
                </Button>
            </Page.Content>
        </Page>
    );
};

export default Identification;
