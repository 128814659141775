import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import AdaptiveIcons from 'components/AdaptiveIcons';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as BulletArrowIcon } from 'assets/icons/bullet-arrow.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/repeat.svg';
import cn from 'classnames';
import Button from 'components/Button';
import AuthForm, { AuthFormData } from 'features/AuthForm';
import Page, { PageProps } from 'components/Page';
import RestAPI from 'libs/RestAPI';
import * as routes from 'routes';
import useAppLoader from 'libs/useAppLoader';
import css from './styles.module.scss';

type Stage = 'edit' | 'error' | 'success';

const pageTypeMap: Record<Stage, PageProps['type']> = {
    edit: 'default',
    error: 'error',
    success: 'success',
};

const DeleteUser: React.FC = () => {
    const { push } = useHistory();
    const { t } = useTranslation();
    const [, setAppLoader] = useAppLoader();

    const [stage, setStage] = useState<Stage>('edit');
    const [userName, setUserName] = useState<string>('');

    const toSettings = () => push(routes.settings());

    const onSubmit = async ({ login, password }: AuthFormData) => {
        setAppLoader(true);

        try {
            await RestAPI.deleteDossier(login, password);
            setAppLoader(false);
            setStage('success');
            setUserName(login);
            return { success: true };
        } catch (e) {
            console.log(e);
        }

        setAppLoader(false);

        setStage('error');

        return { success: false };
    };

    return (
        <Page className={cn(css.page, css[stage])} type={pageTypeMap[stage]}>
            <Header backClick={toSettings} title={t('deleteUser')} />

            {stage === 'edit' && (
                <Page.Content>
                    <Page.Icon className={css.icon}>
                        <AdaptiveIcons name="cross" />
                    </Page.Icon>

                    <AuthForm className={css.form} onSubmit={onSubmit} submitTitle={t('delete')} submitIcon={<CrossIcon />} />
                </Page.Content>
            )}

            {stage === 'error' && <Error repeatAgainClick={() => setStage('edit')} />}
            {stage === 'success' && <Success okClick={toSettings} userName={userName} />}
        </Page>
    );
};

interface ErrorProps {
    repeatAgainClick: () => void;
}

const Error: React.FC<ErrorProps> = ({ repeatAgainClick }) => {
    const { t } = useTranslation();

    const points = [t('unknownError_reason_1')];

    return (
        <Page.Content>
            <Page.Icon>
                <AdaptiveIcons name={'cross'} />
            </Page.Icon>

            <Page.Title className={css.title}>{t('unknownError')}</Page.Title>

            <Page.List title={t('possibleCauses')}>
                {points.map((point, index) => (
                    <Page.ListItem bulletIcon={<BulletArrowIcon />} key={index}>
                        {point}
                    </Page.ListItem>
                ))}
            </Page.List>

            <Page.Footer>
                <Button className={css.errorButton} icon={<RepeatIcon />} appearance="white-fill" onClick={repeatAgainClick}>
                    {t('repeatAgain')}
                </Button>
            </Page.Footer>
        </Page.Content>
    );
};

interface SuccessProps {
    okClick: () => void;
    userName: string;
}

const Success: React.FC<SuccessProps> = ({ okClick, userName }) => {
    const { t } = useTranslation();

    return (
        <Page.Content>
            <Page.Icon>
                <AdaptiveIcons name={'cross'} />
            </Page.Icon>

            <h3 className={css.successTitle}>{t('userDeleted')}</h3>

            <div className={css.successMessage}>
                {t('youHaveDeletedTheUser')}
                <div className={css.userName}>{userName}</div>
            </div>

            <Page.Footer>
                <Button onClick={okClick} className={css.successButton} appearance="white-fill">
                    OK
                </Button>
            </Page.Footer>
        </Page.Content>
    );
};

export default DeleteUser;
