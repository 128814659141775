import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { DEFAULT_MATH_THRESHOLD } from 'consts';
import settingsStorage from 'libs/SttingsStorage';

export interface AppState {
    compareThreshold: number;
    host: string | null;
    login: string | null;
    password: string | null;
}

const initialState: AppState = {
    compareThreshold: settingsStorage.keys.compareThreshold ?? DEFAULT_MATH_THRESHOLD,
    host: settingsStorage.keys.host ?? null,
    login: settingsStorage.keys.login ?? null,
    password: settingsStorage.keys.password ?? null,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCompareThreshold: (state, action: PayloadAction<number>) => {
            state.compareThreshold = action.payload;
            settingsStorage.set({ compareThreshold: action.payload });
        },

        setServerSettings: (state, action: PayloadAction<{ host: string; login: string; password: string }>) => {
            state.host = action.payload.host;
            state.login = action.payload.login;
            state.password = action.payload.password;

            settingsStorage.set(action.payload);
        },
    },
});

export const { setCompareThreshold, setServerSettings } = appSlice.actions;
export const selectCompareThreshold = (state: RootState): number => state.app.compareThreshold;
export const selectServerHost = (state: RootState): string | null => state.app.host;
export const selectServerLogin = (state: RootState): string | null => state.app.login;
export const selectServerPassword = (state: RootState): string | null => state.app.password;

export default appSlice.reducer;
