import cn from 'classnames';
import css from './styles.module.scss';
import { wait } from 'libs';

class Camera {
    private stream: MediaStream | null = null;
    private wrapper: HTMLElement | null = null;
    private container: HTMLDivElement | null = null;
    private sizeObserver: ResizeObserver | null = null;
    private canvas: HTMLCanvasElement | null = null;
    private video: HTMLVideoElement | null = null;
    private initializing = true;
    private streamStarted = false;

    private constraints = {
        video: {
            width: {
                min: 720,
                ideal: 1920,
                max: 1920,
            },
            height: {
                min: 720,
                ideal: 1080,
                max: 1920,
            },
            frameRate: { ideal: 25 },
            facingMode: 'user',
        },
    };

    constructor(wrapper: HTMLElement, facingMode?: 'user' | 'environment') {
        if (facingMode) this.constraints.video.facingMode = facingMode;

        this.wrapper = wrapper;
        this.insertHTML(this.wrapper);
        this.init();
    }

    private insertHTML(element: HTMLElement) {
        element.innerHTML = `<div class="${css.camera}" style="width: ${element.offsetWidth}px; height: ${
            element.offsetHeight
        }px;">
                <video class="${cn(css.video, {
                    [css.environment]: this.constraints.video.facingMode === 'environment',
                })}" autoPlay muted playsinline></video>
                <canvas class="${css.canvas}"></canvas>
                <div class="${css.cover}"></div>
            </div>`;

        this.container = element.querySelector(`.${css.camera}`);
        this.canvas = element.querySelector(`.${css.canvas}`);
        this.video = element.querySelector(`.${css.video}`);
    }

    private init() {
        if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
            this.startStream()
                .then(() => (this.initializing = false))
                .catch((e) => console.log(e));
        }

        if (this.wrapper) {
            this.sizeObserver = new ResizeObserver((entries) => {
                if (this.container) {
                    this.container.style.width = `${entries[0].contentRect.width}px`;
                    this.container.style.height = `${entries[0].contentRect.height}px`;
                }
            });

            this.sizeObserver.observe(this.wrapper);
        }
    }

    private async startStream() {
        if (!this.streamStarted) {
            this.stream = await navigator.mediaDevices.getUserMedia(this.constraints);
            this.handleStream();

            this.streamStarted = true;
        }
    }

    private handleStream() {
        if (!this.stream) return;

        const video = this.video;
        if (video) video.srcObject = this.stream;
    }

    destroy(): void {
        if (this.stream) {
            this.stream.getTracks().forEach(function (track) {
                track.stop();
            });
        }
        if (this.container && this.sizeObserver) this.sizeObserver.unobserve(this.container);

        if (this.wrapper) this.wrapper.innerHTML = '';
    }

    takePhoto(): Promise<Blob | null> {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (this.canvas && this.video) {
                this.canvas.width = this.video.videoWidth;
                this.canvas.height = this.video.videoHeight;

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.canvas.getContext('2d').drawImage(this.video, 0, 0);

                this.canvas.toBlob((blob) => {
                    resolve(blob);
                    // this.downloadFile(blob as Blob);
                });
                return;
            }

            reject();
        });
    }

    recordVideo(duration: number): Promise<Blob | null> {
        return new Promise((resolve, reject) => {
            if (!this.stream) {
                reject();
                return;
            }

            const recorder = new MediaRecorder(this.stream);
            const chunks: Blob[] = [];

            recorder.addEventListener('dataavailable', (e) => {
                chunks.push(e.data);
            });

            recorder.start();

            const stopped = new Promise((resolve, reject) => {
                recorder.addEventListener('stop', resolve);
                recorder.addEventListener('error', reject);
            });

            const recorded = wait(duration).then(() => recorder.state == 'recording' && recorder.stop());

            Promise.all([stopped, recorded])
                .then(() => {
                    console.log(recorder);
                    const blob = new Blob(chunks, { type: 'video/webm' });
                    // this.downloadFile(blob);
                    resolve(blob);
                })
                .catch((e) => reject(e));
        });
    }

    downloadFile(blob: Blob): void {
        const link = document.createElement('a');
        link.style.opacity = '0';
        link.style.position = 'absolute';
        link.style.top = '-2000px';
        link.download = '';
        link.target = '_blank';
        link.href = URL.createObjectURL(blob);

        document.body.append(link);
        link.click();
        link.remove();
    }
}

export default Camera;
