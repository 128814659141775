type TSettingsStorage = {
    compareThreshold?: number;
    host?: string;
    login?: string;
    password?: string;
};

class SettingsStorage {
    private data: TSettingsStorage;

    constructor() {
        this.data = JSON.parse(localStorage.getItem('settingsStorage') ?? '{}');
    }

    get keys(): TSettingsStorage {
        return this.data;
    }

    set(changes: TSettingsStorage): void {
        this.data = {
            ...this.data,
            ...changes,
        };

        localStorage.setItem('settingsStorage', JSON.stringify(this.data));
    }
}

export default new SettingsStorage();
