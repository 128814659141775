import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Selfie, { Props as SelfieProps } from 'components/Selfie';
import * as routes from 'routes';
import { ReactComponent as VerificationIcon } from 'assets/icons/verification.svg';
import AuthForm, { AuthFormData } from 'features/AuthForm';
import Page from 'components/Page';
import Header from 'components/Header';
import Button from 'components/Button';
import AdaptiveIcons from 'components/AdaptiveIcons';
import { ReactComponent as BulletIcon } from 'assets/icons/bullet.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/repeat.svg';
import { ReactComponent as CompareIcon } from 'assets/icons/compare.svg';
import { readBlobAsByteArray } from 'libs';
import { useAppSelector } from 'store/hooks';
import { selectCompareThreshold } from 'App/slice';
import RestAPI from 'libs/RestAPI';
import css from './styles.module.scss';
import useAppLoader from 'libs/useAppLoader';
import { getServerErrorMessage, SERVER_ERROR_TYPE, ServerErrorMessage } from 'libs/serverErrorMessages';
import isServerErrorWithCode from 'libs/isServerErrorWithCode';

type Stage = 'login-password' | 'photo' | 'success' | 'error';

const Verification: React.FC = () => {
    const [stage, setStage] = useState<Stage>('login-password');
    const [userName, setUserName] = useState<string>('');
    const { push } = useHistory();
    const threshold = useAppSelector(selectCompareThreshold);
    const [, setAppLoader] = useAppLoader();
    const [errorType, setErrorType] = useState<SERVER_ERROR_TYPE>(SERVER_ERROR_TYPE.UNKNOWN_ERROR);

    const [verificationStarted, setVerificationStarted] = useState<boolean>(false);
    const [serverError, setServerError] = useState<boolean>(false);

    const toErrorStage = (errorType: SERVER_ERROR_TYPE) => {
        setErrorType(errorType);
        setStage('error');
    };

    useEffect(() => {
        setAppLoader(true);
        RestAPI.startVerification(threshold)
            .then(setVerificationStarted)
            .catch(() => setServerError(true))
            .finally(() => setAppLoader(false));
    }, [threshold]);

    const toMain = () => push(routes.main());
    const toAuth = () => setStage('login-password');
    const toPhoto = () => setStage('photo');

    const successVideo = async (video: Blob | null) => {
        if (video) {
            try {
                setAppLoader(true);
                const file = await readBlobAsByteArray(video);
                const result = await RestAPI.verificationCheckSelfieMovie(file);
                setAppLoader(false);

                if (result.code === 'LISuccess') {
                    setStage('success');
                } else {
                    if (result.code === 'LIFailed' || result.code === 'LINotFound')
                        return toErrorStage(SERVER_ERROR_TYPE.DONT_CONFIRM_IDENTITY);

                    if (result.code === 'LIBadVideo') {
                        const errorMessage =
                            SERVER_ERROR_TYPE[result.message as keyof typeof SERVER_ERROR_TYPE] ??
                            SERVER_ERROR_TYPE.UNKNOWN_ERROR;
                        return toErrorStage(errorMessage);
                    }

                    toErrorStage(SERVER_ERROR_TYPE.UNKNOWN_ERROR);
                }
            } catch (e) {
                console.log(e);
                setAppLoader(false);
                if (isServerErrorWithCode(e)) {
                    if (e.code == 'network_connection')
                        return toErrorStage(SERVER_ERROR_TYPE.NETWORK_ERROR)

                    if (e.code == 'connection_timeout')
                        return toErrorStage(SERVER_ERROR_TYPE.TIMEOUT_ERROR)
                }
                toErrorStage(SERVER_ERROR_TYPE.UNKNOWN_ERROR);
            }
        } else setStage('error');
        setAppLoader(false);
    };

    const successAuth = ({ name }: { name: string }) => {
        setUserName(name);
        toPhoto();
    };

    if (!verificationStarted)
        return serverError ? <div className="text-h3">Server connection error, try again later</div> : null;

    if (stage === 'error')
        return <Error backClick={toPhoto} repeatAgainClick={toPhoto} errorMessage={getServerErrorMessage(errorType)} />;
    if (stage === 'success') return <Success backClick={toMain} okClick={toMain} userName={userName} />;
    if (stage === 'photo') return <Photo back={toAuth} complete={successVideo} userName={userName} />;

    return <Auth backClick={toMain} complete={successAuth} />;
};

type PhotoProps = Omit<SelfieProps, 'buttonTitle' | 'children'> & { userName: string };

const Photo: React.FC<PhotoProps> = ({ userName, ...rest }) => {
    const { t } = useTranslation();

    return (
        <Selfie title={t('verification')} buttonTitle={t('confirmIdentity')} buttonIcon={<CompareIcon />} {...rest}>
            <div className={cn(css.user, 'text-normal')}>
                {t('user')}
                <div>
                    <strong>{userName}</strong>
                </div>
            </div>
        </Selfie>
    );
};

type ErrorProps = {
    backClick: () => void;
    repeatAgainClick: () => void;
    errorMessage: ServerErrorMessage;
};

const Error: React.FC<ErrorProps> = ({ backClick, repeatAgainClick, errorMessage: { title, points } }) => {
    const { t } = useTranslation();

    return (
        <Page type="error">
            <Header backClick={backClick} title={t('identification')} />

            <Page.Content>
                <Page.Icon>
                    <AdaptiveIcons name="cross" />
                </Page.Icon>

                <Page.Title className={css.errorTitle}>{title}</Page.Title>

                <Page.List title={t('itCanBe')}>
                    {points.map((point, index) => (
                        <Page.ListItem bulletIcon={<BulletIcon />} key={index}>
                            {point}
                        </Page.ListItem>
                    ))}
                </Page.List>

                <Page.Footer>
                    <Button icon={<RepeatIcon />} appearance="white-fill" onClick={repeatAgainClick}>
                        {t('repeatAgain')}
                    </Button>
                </Page.Footer>
            </Page.Content>
        </Page>
    );
};

interface SuccessProps {
    backClick: () => void;
    okClick: () => void;
    userName: string;
}

const Success: React.FC<SuccessProps> = ({ backClick, okClick, userName }) => {
    const { t } = useTranslation();

    return (
        <Page type="success">
            <Header backClick={backClick} title={t('verification')} />

            <Page.Content className={css.successContent}>
                <Page.Icon>
                    <AdaptiveIcons name="mark" />
                </Page.Icon>

                <h3 className={css.successTitle}>{t('verificationSuccess')}</h3>

                <div className={css.successMessage}>
                    {t('hello')},<div className={css.userName}>{userName}</div>
                    <div className={css.successMessageText}>{t('verificationSuccessMessage')}</div>
                </div>

                <Button onClick={okClick} className={css.successButton} appearance="white-fill">
                    OK
                </Button>
            </Page.Content>
        </Page>
    );
};

type AuthProps = {
    backClick: () => void;
    complete: (userData: { name: string }) => void;
};

const Auth: React.FC<AuthProps> = ({ backClick, complete }) => {
    const { t } = useTranslation();
    const [loading, setAppLoader] = useAppLoader();

    const onSubmit = async ({
        login,
        password,
    }: {
        login: string;
        password: string;
    }): Promise<{ success: boolean; errors?: { [key in keyof AuthFormData]?: string } }> => {
        setAppLoader(true);

        try {
            const result = await RestAPI.verificationLogin(login, password);
            setAppLoader(false);

            if (result) {
                complete(result);
                return { success: true };
            }

            return {
                success: false,
                errors: { login: 'badLoginOrPassword' },
            };
        } catch (e) {
            console.log(e);
        }

        setAppLoader(false);

        return {
            success: false,
            errors: { login: 'connection' },
        };
    };

    return (
        <Page type="success">
            <Header backClick={backClick} title={t('verification')} />

            <Page.Content className={css.authContent}>
                <Page.Icon>
                    <VerificationIcon width={77} height={77} />
                </Page.Icon>

                <AuthForm className={css.authForm} onSubmit={onSubmit} disabled={loading} submitTitle={t('logIn')} />
            </Page.Content>
        </Page>
    );
};

export default Verification;
