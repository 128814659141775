import React, { useEffect, useState } from 'react';

import { ReactComponent as GearIcon } from 'assets/icons/gear.svg';
import { ReactComponent as GearBigIcon } from 'assets/icons/gear-big.svg';
import { ReactComponent as EllipsisIcon } from 'assets/icons/ellipsis.svg';
import { ReactComponent as EllipsisBigIcon } from 'assets/icons/ellipsis-big.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as CrossBigIcon } from 'assets/icons/cross-big.svg';
import { ReactComponent as MarkIcon } from 'assets/icons/mark.svg';
import { ReactComponent as MarkBigIcon } from 'assets/icons/mark-big.svg';
import useScreenType from 'libs/useScreenType';

type IconByScreenType = {
    mobile: React.FC;
    desktop: React.FC;
};

const icons: { [key: string]: IconByScreenType } = {
    gear: {
        mobile: GearIcon,
        desktop: GearBigIcon,
    },
    cross: {
        mobile: CrossIcon,
        desktop: CrossBigIcon,
    },
    ellipsis: {
        mobile: EllipsisIcon,
        desktop: EllipsisBigIcon,
    },
    mark: {
        mobile: MarkIcon,
        desktop: MarkBigIcon,
    },
};

export interface Props {
    name: 'gear' | 'cross' | 'ellipsis' | 'mark';
}

const AdaptiveIcons: React.FC<Props> = ({ name }) => {
    const [screenType] = useScreenType();

    const getIcon = (): React.FC => {
        return icons[name][screenType];
    };

    const [IconComponent, setIconComponent] = useState<React.FC>(getIcon);

    useEffect(() => {
        setIconComponent(getIcon);
    }, [screenType]);

    return <IconComponent />;
};

export default AdaptiveIcons;
