import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'components/Page';
import Header from 'components/Header';
import AuthForm, { AuthFormData } from 'features/AuthForm';
import AdaptiveIcons from 'components/AdaptiveIcons';
import RestAPI from 'libs/RestAPI';
import css from './styles.module.scss';
import useAppLoader from 'libs/useAppLoader';
import isServerErrorWithCode from 'libs/isServerErrorWithCode';

export interface Props {
    backClick: () => void;
    complete: () => void;
}

const Registration: React.FC<Props> = ({ backClick, complete }) => {
    const { t } = useTranslation();
    const [loading, setAppLoader] = useAppLoader();

    const onSubmit = async ({
        login,
        password,
    }: {
        login: string;
        password: string;
    }): Promise<{ success: boolean; errors?: { [key in keyof AuthFormData]?: string } }> => {
        setAppLoader(true);
        try {
            const result = await RestAPI.onboardingRegistration(login, password);

            if (result.code === 'Ok') {
                complete();
                return { success: true };
            }

            setAppLoader(false);

            return {
                success: false,
                errors: {
                    ...(result.code === 'LoginExists' ? { login: 'loginExists' } : {}),
                },
            };
        } catch (e) {
            if (isServerErrorWithCode(e)) {
                if (e.code == 'network_connection')
                    return {
                        success: false,
                        errors: { login: 'networkError' },
                    };

                if (e.code == 'connection_timeout')
                    return {
                        success: false,
                        errors: { login: 'timeoutError' },
                    };
            }
        }

        setAppLoader(false);

        return {
            success: false,
            errors: { login: 'connection' },
        };
    };

    return (
        <Page type="success">
            <Header backClick={backClick} title={t('registration')} />

            <Page.Content className={css.content}>
                <Page.Icon>
                    <AdaptiveIcons name="mark" />
                </Page.Icon>

                <AuthForm className={css.form} onSubmit={onSubmit} disabled={loading} />
            </Page.Content>
        </Page>
    );
};

export default Registration;
