import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as SignUpIcon } from 'assets/icons/signup.svg';
import Header from 'components/Header';
import AdaptiveIcons from 'components/AdaptiveIcons';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import InputField from 'components/form/InputField';
import Button from 'components/Button';
import Page, { PageProps } from 'components/Page';
import RetAPI from 'libs/RestAPI';
import * as routes from 'routes';
import css from './styles.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useAppLoader from 'libs/useAppLoader';
import { selectServerHost, selectServerLogin, selectServerPassword, setServerSettings } from 'App/slice';
import isServerErrorWithCode from 'libs/isServerErrorWithCode';

type Stage = 'readonly' | 'edit' | 'success';

type FormData = {
    host: string;
    login: string;
    password: string;
};

const schema = yup
    .object({
        host: yup.string().url().required(),
        login: yup.string().required(),
        password: yup.string().required(),
    })
    .required();

const pageTypeMap: { [key: string]: PageProps['type'] } = {
    readonly: 'success',
    edit: 'default',
    success: 'success',
};

const ServerSettings: React.FC = () => {
    const [checking, setChecking] = useState<boolean>(false);
    const isFirstRun = useRef<boolean>(false);
    const { push } = useHistory();
    const { t } = useTranslation();
    const [, setAppLoader] = useAppLoader();

    const dispatch = useAppDispatch();
    const host = useAppSelector(selectServerHost);
    const login = useAppSelector(selectServerLogin);
    const password = useAppSelector(selectServerPassword);

    useEffect(() => {
        isFirstRun.current = !host || !login || !password;
    }, []);

    const [stage, setStage] = useState<Stage>(host ? 'readonly' : 'edit');

    const headerTitles = {
        readonly: t('serverSettings'),
        edit: t('connectionParams'),
        success: t('connection'),
    };

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const toSettings = () => push(routes.settings());

    const onSubmit = async (data: FormData) => {
        if (stage === 'edit') {
            setChecking(true);

            try {
                setAppLoader(true);
                await RetAPI.checkConnection(data);
                setAppLoader(false);
                setStage('success');
            } catch (e) {
                setAppLoader(false);
                
                setError('host', {
                    type: 'connection',
                    message: 'Connection error',
                });
                
                console.log('check connection error', e);
            }

            setChecking(false);
        }
        if (stage === 'success') {
            dispatch(setServerSettings(data));

            if (isFirstRun.current) push(routes.createProfile());
            else setStage('readonly');
        }
    };

    const editSettings = () => setStage('edit');

    return (
        <Page className={cn(css.page, css[stage])} type={pageTypeMap[stage] ?? 'default'}>
            <Header backClick={toSettings} title={headerTitles[stage]} />

            <Page.Content>
                <Page.Icon className={css.icon}>
                    <AdaptiveIcons name="ellipsis" />
                </Page.Icon>

                {stage === 'success' && <Page.Title className={css.title}>{t('serverSettingsSuccessTitle')}</Page.Title>}

                <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="host"
                        control={control}
                        defaultValue={host ?? ''}
                        render={({ field }) => (
                            <InputField
                                {...field}
                                autoComplete="no"
                                className={css.field}
                                placeholder={t('serverNameOrIp')}
                                errors={errors.host ? [t(`errors.${errors.host.type}`)] : undefined}
                                readOnly={['readonly', 'success'].includes(stage)}
                                disabled={checking}
                            />
                        )}
                    />

                    <Controller
                        name="login"
                        control={control}
                        defaultValue={login ?? ''}
                        render={({ field }) => (
                            <InputField
                                {...field}
                                autoComplete="no"
                                className={css.field}
                                placeholder={t('userName')}
                                errors={errors.login ? [t(`errors.${errors.login.type}`)] : undefined}
                                readOnly={['readonly', 'success'].includes(stage)}
                                disabled={checking}
                            />
                        )}
                    />

                    {stage !== 'readonly' && (
                        <Controller
                            name="password"
                            control={control}
                            defaultValue={password ?? ''}
                            render={({ field }) => (
                                <InputField
                                    {...field}
                                    autoComplete="no"
                                    className={css.field}
                                    type="password"
                                    errors={errors.password ? [t(`errors.${errors.password.type}`)] : undefined}
                                    placeholder={t('password')}
                                    readOnly={['readonly', 'success'].includes(stage)}
                                    disabled={checking}
                                />
                            )}
                        />
                    )}

                    {stage !== 'readonly' && (
                        <Button
                            type="submit"
                            className={css.submit}
                            icon={<SignUpIcon />}
                            appearance="white-fill"
                            disabled={checking}
                        >
                            {stage === 'edit' && t('check')}
                            {stage === 'success' && t('save')}
                        </Button>
                    )}

                    {stage === 'readonly' && (
                        <Button
                            type="button"
                            className={css.submit}
                            icon={<EditIcon />}
                            appearance="white-fill"
                            onClick={editSettings}
                        >
                            {t('change')}
                        </Button>
                    )}
                </form>
            </Page.Content>
        </Page>
    );
};

export default ServerSettings;
