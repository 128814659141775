import i18next from 'i18next';

export enum SERVER_ERROR_TYPE {
    BAD_VIDEO,
    DIFFERENT_FACES,
    UNKNOWN_ERROR,
    NO_FACE,
    NOT_LIVE,
    SEVERAL_FACES,
    FACE_TOO_BIG,
    FACE_TOO_SMALL,
    FACE_CLOSE_TO_BORDER,
    FACE_OVEREXPOSED,
    FACE_UNDEREXPOSED,
    PROFILE_NOT_FOUND,
    DONT_CONFIRM_IDENTITY,
    NETWORK_ERROR,
    TIMEOUT_ERROR,
}

export interface ServerErrorMessage {
    title: string;
    points: Array<string>;
}

export const getServerErrorMessage = (errorType: SERVER_ERROR_TYPE): ServerErrorMessage => {
    const errorsMessages: Record<SERVER_ERROR_TYPE, ServerErrorMessage> = {
        [SERVER_ERROR_TYPE.BAD_VIDEO]: {
            title: i18next.t('failedToPassALivePersonCheck'),
            points: [i18next.t('takePhotoYourFace_error_1')],
        },
        [SERVER_ERROR_TYPE.DIFFERENT_FACES]: {
            title: i18next.t('selfieAndPhotoInIdNotSimilar'),
            points: [
                i18next.t('takePhotoYourFace_compareError_1'),
                i18next.t('takePhotoYourFace_compareError_2'),
                i18next.t('takePhotoYourFace_compareError_3'),
            ],
        },
        [SERVER_ERROR_TYPE.UNKNOWN_ERROR]: {
            title: i18next.t('unknownError'),
            points: [i18next.t('unknownError_reason_1')],
        },
        [SERVER_ERROR_TYPE.NO_FACE]: {
            title: i18next.t('noFaces'),
            points: [i18next.t('noFaces_reason_1')],
        },
        [SERVER_ERROR_TYPE.NOT_LIVE]: {
            title: i18next.t('failedToPassALivePersonCheck'),
            points: [i18next.t('takePhotoYourFace_error_1')],
        },
        [SERVER_ERROR_TYPE.SEVERAL_FACES]: {
            title: i18next.t('manyFaces'),
            points: [i18next.t('manyFaces_reason_1')],
        },
        [SERVER_ERROR_TYPE.FACE_TOO_BIG]: {
            title: i18next.t('faceTooBig'),
            points: [i18next.t('faceTooBig_reason_1')],
        },
        [SERVER_ERROR_TYPE.FACE_TOO_SMALL]: {
            title: i18next.t('faceTooSmall'),
            points: [i18next.t('faceTooSmall_reason_1')],
        },
        [SERVER_ERROR_TYPE.FACE_CLOSE_TO_BORDER]: {
            title: i18next.t('faceTooCloseToBorder'),
            points: [i18next.t('faceTooCloseToBorder_reason_1')],
        },
        [SERVER_ERROR_TYPE.FACE_OVEREXPOSED]: {
            title: i18next.t('faceTooCloseToBorder'),
            points: [i18next.t('faceTooCloseToBorder_reason_1')],
        },
        [SERVER_ERROR_TYPE.FACE_UNDEREXPOSED]: {
            title: i18next.t('faceUnderexposed'),
            points: [i18next.t('faceUnderexposed_reason_1')],
        },
        [SERVER_ERROR_TYPE.PROFILE_NOT_FOUND]: {
            title: i18next.t('profileNotFindInDataBase'),
            points: [i18next.t('identification_error_1')],
        },
        [SERVER_ERROR_TYPE.DONT_CONFIRM_IDENTITY]: {
            title: i18next.t('couldDontConfirmYourIdentity'),
            points: [i18next.t('verificationErrorReason_1')],
        },
        [SERVER_ERROR_TYPE.NETWORK_ERROR]: {
            title: i18next.t('errors.networkError'),
            points: [i18next.t('unknownError_reason_1')],
        },
        [SERVER_ERROR_TYPE.TIMEOUT_ERROR]: {
            title: i18next.t('errors.timeoutError'),
            points: [i18next.t('unknownError_reason_1')],
        },
    };

    return errorsMessages[errorType];
};
