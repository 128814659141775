import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import Page from 'components/Page';
import Header from 'components/Header';
import { ReactComponent as Ellipsis } from 'assets/icons/ellipsis.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import Button from 'components/Button';
import Slider from 'components/Slider';
import AdaptiveIcons from 'components/AdaptiveIcons';
import * as routes from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCompareThreshold, setCompareThreshold } from 'App/slice';
import css from './styles.module.scss';

const languageOptions = [
    {
        value: 'ru',
        // eslint-disable-next-line i18n/no-russian-character
        label: 'Русский',
    },
    {
        value: 'en',
        label: 'English',
    },
    {
        value: 'es',
        // eslint-disable-next-line i18n/no-russian-character
        label: 'Еspañol',
    },
    {
        value: 'pt',
        label: 'Português',
    },
];

const languageMap = new Map(languageOptions.map((i) => [i.value, i.label]));

const Settings: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { push } = useHistory();
    const dispatch = useAppDispatch();
    const compareThreshold = useAppSelector(selectCompareThreshold);
    const onChange = (value: number) => dispatch(setCompareThreshold(value));
    const toServerSettings = () => push(routes.serverSettings());
    const toDeleteUser = () => push(routes.deleteUser());

    const onChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        i18n.changeLanguage(event.currentTarget.value);
    };

    console.log(i18n.language, i18n.languages, i18n);

    return (
        <Page>
            <Header backClick={() => push('/')} title={t('settings')} />

            <Page.Content>
                <Page.Icon className={css.icon}>
                    <AdaptiveIcons name="gear" />
                </Page.Icon>

                <div className={cn(css.label, 'text-normal')}>{t('matchThreshold')}</div>

                <div className={css.field}>
                    <Slider min={0.5} step={0.01} max={1} onChange={onChange} value={compareThreshold} />
                </div>

                <Button
                    type="button"
                    className={css.button}
                    icon={<Ellipsis />}
                    appearance="white-stroke"
                    onClick={toServerSettings}
                >
                    {t('serverSettings')}
                </Button>

                <div className={cn(css.button, css.selectLanguage)}>
                    <label>
                        <select className={css.select} value={i18n.language} onChange={onChangeLanguage}>
                            {languageOptions.map((o, index) => (
                                <option key={index} value={o.value}>
                                    {o.label}
                                </option>
                            ))}
                        </select>

                        <Button type="button" icon={<Ellipsis />} appearance="white-stroke">
                            Language: {languageMap.get(i18n.language.split('-')[0])}
                        </Button>
                    </label>
                </div>

                <Button
                    type="button"
                    className={css.button}
                    icon={<CrossIcon />}
                    appearance="white-stroke"
                    onClick={toDeleteUser}
                >
                    {t('deleteUser')}
                </Button>
            </Page.Content>
        </Page>
    );
};

export default Settings;
