import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import Button from 'components/Button';
import css from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
    backClick?: () => void;
    backDisabled?: boolean;
    title?: string;
}

const Header: React.FC<HeaderProps> = ({ backClick, backDisabled, children, className, title, ...rest }) => {
    const { t } = useTranslation();

    return (
        <header className={cn(css.header, className)} {...rest}>
            <div className={css.logo}>
                <LogoIcon width={36} height={33} />
            </div>

            {backClick && (
                <Button
                    className={css.button}
                    onClick={backClick}
                    dimension="s"
                    disabled={backDisabled}
                    appearance="white-transparent"
                    icon={<ArrowLeftIcon />}
                >
                    <span className={css.buttonTitle}>{t('back')}</span>
                </Button>
            )}

            {title && <div className={cn(css.title, 'text-normal')}>{title}</div>}
            {children}
        </header>
    );
};

export default Header;
