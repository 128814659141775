import { useEffect, useContext } from 'react';
import { AppLoaderContext } from 'App';

export default function useAppLoader() {
    const { showLoader, setLoader } = useContext(AppLoaderContext);

    useEffect(() => {
        return () => setLoader(false);
    }, []);

    const setLoaderValue = (value: boolean): void => setLoader(value);

    return [showLoader, setLoaderValue] as const;
}
