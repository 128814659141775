import React from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    inputElementClassName?: string;
}

const Input: React.FC<Props> = ({ inputElementClassName, className, ...rest }) => {
    return (
        <div className={cn(css.inputWrapper, className)}>
            <input className={cn(css.input, inputElementClassName)} {...rest} />
        </div>
    );
};

export default Input;
