import React from 'react';
import type { ButtonHTMLAttributes } from 'react';
import css from './styles.module.scss';
import cn from 'classnames';

export type ButtonDimension = 'xs' | 's' | 'm' | 'l';
export type ButtonTextAlign = 'center' | 'left' | 'right';

export type ButtonAppearance =
    | 'white-stroke'
    | 'white-fill'
    | 'violet-stroke'
    | 'violet-fill'
    | 'white-transparent'
    | 'violet-transparent';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    textAlign?: ButtonTextAlign;
    dimension?: ButtonDimension;
    appearance?: ButtonAppearance;
    icon?: React.ReactNode;
    displayAsRound?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    className,
    textAlign = 'center',
    dimension = 'm',
    appearance = 'white-stroke',
    type = 'button',
    icon,
    displayAsRound,
    children,
    ...rest
}) => {
    const [color, styleType] = appearance.split('-');

    return (
        <button
            className={cn(
                css.button,
                className,
                `dimension-${dimension}`,
                `color-${color}`,
                `type-${styleType}`,
                `align-${textAlign}`,
                { [css.round]: displayAsRound },
            )}
            type={type}
            {...rest}
        >
            {icon && <div className={css.icon}>{icon}</div>}
            {children && <div className={css.content}>{children}</div>}
        </button>
    );
};

export default Button;
