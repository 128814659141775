import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/styles/global.scss';
import App from 'App';
import { store } from 'store';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import 'locale';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
