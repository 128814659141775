import { API_VERSION } from 'consts';
import settingsStorage from 'libs/SttingsStorage';


type serverSettings = {
    host: string;
    login: string;
    password: string;
};

type identificationResult = {
    code: string;
    dossier?: any;
    message?: any;
};

type verificationResult = {
    code: string;
    message?: any;
};

class RestAPI {
    apiInstance: FFL.FFLApi | undefined;
    onboardingInstance: FFL.FFLOnboarding | undefined;
    verificationInstance: FFL.FFLVerification | undefined;

    constructor() {
        const { host, login, password } = settingsStorage.keys;
        if (host && login && password) this.createConnection({ host, login, password });
    }

    createConnection(settings: serverSettings) {
        const { host, login, password } = settings;
        this.apiInstance = new FFL.FFLApi(host, login, password, API_VERSION);
        console.log('create connection', this.apiInstance);
        return this.apiInstance;
    }

    initApi(): Promise<unknown> {
        if (!this.apiInstance) return Promise.reject('Api instance is not defined');
        return this.apiInstance.init();
    }

    checkConnection(settings: serverSettings) {
        this.createConnection(settings);
        return this.initApi();
    }

    async deleteDossier(name: string, password: string) {
        if (!this.apiInstance) return Promise.reject('Api instance is not defined');
        await this.initApi();
        await this.apiInstance.deleteDossierByName(name, password);
    }

    async startOnboarding(threshold: number): Promise<boolean> {
        if (!this.apiInstance) return Promise.reject('Api instance is not defined');
        await this.initApi();
        this.onboardingInstance = new FFL.FFLOnboarding(this.apiInstance, threshold);
        return true;
    }

    async onboardingAddDocument(document: Uint8Array, name: string): Promise<FFLResponse> {
        if (!this.onboardingInstance) return Promise.reject('Onboarding instance is not defined');
        return this.onboardingInstance.addDocument(document, name);
    }

    async onboardingAddSelfieMovie(movie: Uint8Array, name: string): Promise<FFLResponse> {
        if (!this.onboardingInstance) return Promise.reject('Onboarding instance is not defined');
        return this.onboardingInstance.addSelfieMovie(movie, name);
    }

    async onboardingRegistration(login: string, password: string): Promise<FFLResponse> {
        if (!this.onboardingInstance) return Promise.reject('Onboarding instance is not defined');
        return this.onboardingInstance.createDossier(login, password);
    }

    async startVerification(threshold: number): Promise<boolean> {
        if (!this.apiInstance) return Promise.reject('Api instance is not defined');
        await this.initApi();
        this.verificationInstance = new FFL.FFLVerification(this.apiInstance, threshold);
        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async verificationLogin(login: string, password: string): Promise<FFLLoginResponse> {
        if (!this.verificationInstance) return Promise.reject('Verification instance is not defined');
        return this.verificationInstance.login(login, password);
    }

    async verificationCheckSelfieMovie(movie: Uint8Array): Promise<verificationResult> {
        if (!this.verificationInstance) return Promise.reject('Verification instance is not defined');
        return this.verificationInstance.verify(movie);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async identification(movie: Uint8Array): Promise<identificationResult> {
        if (!this.apiInstance) return Promise.reject('Api instance is not defined');
        await this.initApi();
        const identificationInstance = new FFL.FFLIdentification(this.apiInstance);
        return identificationInstance.perform(movie);
    }
}

export default new RestAPI();
