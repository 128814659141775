export function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
}

export function wait(delayInMS: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, delayInMS));
}

export const readBlobAsByteArray = (blob: Blob): Promise<Uint8Array> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const arrayBuffer = reader.result;

            resolve(new Uint8Array(arrayBuffer as ArrayBuffer));
        };

        reader.onerror = (e) => reject(e);
        reader.readAsArrayBuffer(blob);
    });
};

export type ScreenType = 'mobile' | 'desktop';

export const getScreenType = (width: number): ScreenType => {
    switch (true) {
        case width > 980:
            return 'desktop';
        default:
            return 'mobile';
    }
};
