import { useEffect, useState } from 'react';

const useWindowInnerHeight = (): number => {
    const [value, setValue] = useState(window.innerHeight);

    useEffect(() => {
        setInterval(() => {
            setValue(window.innerHeight);
        }, 50);
    }, []);

    return value;
};

export default useWindowInnerHeight;
