import React, { useEffect, useState } from 'react';
import PhotoId from './PhotoId';
import PhotoFace from './PhotoFace';
import Registration from './Registration';
import Success from './Success';
import { useHistory } from 'react-router-dom';
import * as routes from 'routes';
import RestAPI from 'libs/RestAPI';
import { useAppSelector } from 'store/hooks';
import { selectCompareThreshold } from 'App/slice';
import useAppLoader from 'libs/useAppLoader';

enum Stage {
    PHOTO_ID,
    PHOTO_FACE,
    LOGIN_PASSWORD,
    SUCCESS,
}

const CreateProfile: React.FC = () => {
    const [stage, setStage] = useState<Stage>(Stage.PHOTO_ID);
    const { push } = useHistory();
    const threshold = useAppSelector(selectCompareThreshold);

    const [onboardingStarted, setOnboardingStarted] = useState<boolean>(false);
    const [serverError, setServerError] = useState<boolean>(false);
    const [, setAppLoader] = useAppLoader();

    useEffect(() => {
        setAppLoader(true);
        RestAPI.startOnboarding(threshold)
            .then(setOnboardingStarted)
            .catch(() => setServerError(true))
            .finally(() => setAppLoader(false));
    }, [threshold]);

    const toPhotoIdStage = () => setStage(Stage.PHOTO_ID);
    const toPhotoFaceStage = () => setStage(Stage.PHOTO_FACE);
    const toMainPage = () => push(routes.main());
    const toMainLoginPage = () => setStage(Stage.LOGIN_PASSWORD);
    const toSuccessPage = () => setStage(Stage.SUCCESS);

    if (!onboardingStarted) return serverError ? <div className="text-h3">Server connection error, try again later</div> : null;

    if (stage === Stage.PHOTO_FACE) return <PhotoFace backClick={toPhotoIdStage} complete={toMainLoginPage} />;
    if (stage === Stage.LOGIN_PASSWORD) return <Registration backClick={toPhotoFaceStage} complete={toSuccessPage} />;
    if (stage === Stage.SUCCESS) return <Success backClick={toMainPage} continueClick={toMainPage} />;

    return <PhotoId backClick={toMainPage} complete={toPhotoFaceStage} />;
};

export default CreateProfile;
