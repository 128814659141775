import React, { createRef, useEffect, useRef, useState } from 'react';
import uniqid from 'uniqid';
import Page from 'components/Page';
import Header from 'components/Header';
import cn from 'classnames';
import Button from 'components/Button';
import AdaptiveIcons from 'components/AdaptiveIcons';
import profileImageSrc from './profile.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MarkIcon } from 'assets/icons/mark.svg';
import { ReactComponent as TakePhotoIcon } from 'assets/icons/take-photo.svg';
import { ReactComponent as BulletArrowIcon } from 'assets/icons/bullet-arrow.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/repeat.svg';
import Camera from 'libs/camera/Camera';
import css from './styles.module.scss';
import { readBlobAsByteArray } from 'libs';
import RestAPI from 'libs/RestAPI';
import useScreenType from 'libs/useScreenType';
import useAppLoader from '../../../../libs/useAppLoader';
import { SERVER_ERROR_TYPE, getServerErrorMessage, ServerErrorMessage } from 'libs/serverErrorMessages';
import isServerErrorWithCode from 'libs/isServerErrorWithCode';

export interface Props {
    backClick: () => void;
    complete: () => void;
}

const PhotoId: React.FC<Props> = ({ complete, backClick }) => {
    const [stage, setStage] = useState<'info' | 'photo' | 'error' | 'exists-error'>('info');
    const [errorType, setErrorType] = useState<SERVER_ERROR_TYPE | undefined>();
    const [, setAppLoader] = useAppLoader();

    const toPhotoStage = () => setStage('photo');
    const toInfoStage = () => setStage('info');
    const toExistErrorStage = () => setStage('exists-error');

    const toErrorStage = (errorType?: SERVER_ERROR_TYPE) => {
        setErrorType(errorType);
        setStage('error');
    };

    const photoSuccess = async (photo: Uint8Array) => {
        try {
            setAppLoader(true);
            const result = await RestAPI.onboardingAddDocument(photo, uniqid('selfie-', '.png'));
            setAppLoader(false);
            if (result.code === 'Ok') complete();
            else {
                if (result?.code === 'FaceExists') toExistErrorStage();
                else toErrorStage();

                console.log('add document error', result);
            }
        } catch (e) {
            console.log(e);
            setAppLoader(false);

            if (isServerErrorWithCode(e)) {
                if (e.code == 'network_connection')
                    return toErrorStage(SERVER_ERROR_TYPE.NETWORK_ERROR)

                if (e.code == 'connection_timeout')
                    return toErrorStage(SERVER_ERROR_TYPE.TIMEOUT_ERROR)
            }

            toErrorStage();
        }
    };

    if (stage === 'photo') return <Photo backClick={toInfoStage} photoSuccess={photoSuccess} />;
    if (stage === 'exists-error') return <ExistsError backClick={toPhotoStage} repeatAgainClick={toPhotoStage} />;

    if (stage === 'error') return <Error
        backClick={toPhotoStage}
        repeatAgainClick={toPhotoStage}
        errorMessage={errorType ? getServerErrorMessage(errorType) : undefined}
    />;

    return <Info backClick={backClick} startClick={toPhotoStage} />;
};

type InfoProps = {
    backClick: () => void;
    startClick: () => void;
};

const Info: React.FC<InfoProps> = ({ backClick, startClick }) => {
    const { t } = useTranslation();

    return (
        <Page>
            <Header backClick={backClick} title={t('firstRun')} />

            <Page.Content className={css.infoContent}>
                <h4 className={css.infoTitle}>{t('photoIdTitle')}</h4>

                <div className={cn(css.infoText, 'text-normal')}>{t('photoIdDescription')}</div>

                <Page.DesktopRightSideContent className={css.desktopSidebar}>
                    <img className={css.image} src={profileImageSrc} width={470} height={392} alt="Profile image" />
                </Page.DesktopRightSideContent>

                <Button className={css.startButton} appearance="violet-fill" onClick={startClick}>
                    {t('start')}
                </Button>
            </Page.Content>
        </Page>
    );
};

type ErrorProps = {
    backClick: () => void;
    repeatAgainClick: () => void;
    errorMessage?: ServerErrorMessage;
};

const Error: React.FC<ErrorProps> = ({ backClick, repeatAgainClick, errorMessage }) => {
    const { t } = useTranslation();

    const title = errorMessage?.title ?? t('badPhoto');
    const points = errorMessage?.points ?? [t('takePhotoYourId_error_1'), t('takePhotoYourId_error_2')];

    return (
        <Page type="error">
            <Header backClick={backClick} title={t('error')} />

            <Page.Content>
                <Page.Icon>
                    <AdaptiveIcons name="cross" />
                </Page.Icon>

                <Page.Title>{title}</Page.Title>

                <Page.List title={t('itCanBe')}>
                    {points.map((point, index) => (
                        <Page.ListItem bulletIcon={<BulletArrowIcon />} key={index}>
                            {point}
                        </Page.ListItem>
                    ))}
                </Page.List>

                <Page.Footer>
                    <Button icon={<RepeatIcon />} appearance="white-fill" onClick={repeatAgainClick}>
                        {t('repeatAgain')}
                    </Button>
                </Page.Footer>
            </Page.Content>
        </Page>
    );
};

type ExistsErrorProps = {
    backClick: () => void;
    repeatAgainClick: () => void;
};

const ExistsError: React.FC<ExistsErrorProps> = ({ backClick, repeatAgainClick }) => {
    const { t } = useTranslation();

    return (
        <Page type="error">
            <Header backClick={backClick} title={t('error')} />

            <Page.Content>
                <Page.Icon>
                    <AdaptiveIcons name="cross" />
                </Page.Icon>

                <Page.Title>{t('userExists')}</Page.Title>

                <Page.Footer>
                    <Button icon={<RepeatIcon />} appearance="white-fill" onClick={repeatAgainClick}>
                        {t('repeatAgain')}
                    </Button>
                </Page.Footer>
            </Page.Content>
        </Page>
    );
};

type PhotoProps = {
    backClick: () => void;
    photoSuccess: (file: Uint8Array) => void;
};

const Photo: React.FC<PhotoProps> = ({ backClick, photoSuccess }) => {
    const { t } = useTranslation();
    const cameraContainerRef = createRef<HTMLDivElement>();
    const cameraInstance = useRef<Camera>();
    const [screenType] = useScreenType();

    useEffect(() => {
        if (cameraContainerRef.current && !cameraInstance.current) {
            cameraInstance.current = new Camera(cameraContainerRef.current, 'environment');
        }

        return () => {
            cameraInstance.current?.destroy();
        };
    }, []);

    const takePhoto = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.currentTarget.disabled = true;

        cameraInstance.current
            ?.takePhoto()
            ?.then(async (blob) => {
                console.log(blob);

                if (blob) {
                    const file = await readBlobAsByteArray(blob);
                    photoSuccess(file);
                }
            })
            .catch((e) => console.log(e))
            .finally(() => {
                if (event.currentTarget) event.currentTarget.disabled = false;
            });
    };

    const options = [t('takePhotoYourId_option_1')];

    return (
        <Page>
            <Header className={css.header} backClick={backClick}>
                {screenType === 'mobile' && <h4 className={css.title}>{t('takePhotoYourId')}</h4>}
            </Header>

            <Page.Content className={css.content}>
                {screenType === 'desktop' && <Page.Title>{t('takePhotoYourId')}</Page.Title>}

                <Page.DesktopRightSideContent className={css.photoDesktopSidebar}>
                    <div ref={cameraContainerRef} className={css.camera} />
                    <div className={css.frame} />
                </Page.DesktopRightSideContent>

                <ul className={css.list}>
                    {options.map((i, index) => (
                        <li className={css.item} key={index}>
                            <MarkIcon className={css.itemIcon} width={29} height={29} />
                            <div className={css.itemTitle}>{i}</div>
                        </li>
                    ))}
                </ul>

                <Button className={css.button} appearance="violet-fill" icon={<TakePhotoIcon />} onClick={takePhoto}>
                    {t('takePhoto')}
                </Button>
            </Page.Content>
        </Page>
    );
};

export default PhotoId;
