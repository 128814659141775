import React, { HTMLAttributes, useEffect, useState } from 'react';
import { ReactComponent as BulletIcon } from 'assets/icons/bullet.svg';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

export interface PageProps extends HTMLAttributes<HTMLDivElement> {
    type?: 'error' | 'success' | 'default';
    appearance?: 'main' | 'default';
}

const Page: React.FC<PageProps> = ({ children, className, type = 'default', appearance = 'default', ...rest }) => {
    const { t } = useTranslation();

    return (
        <div className={cn(css.page, css[type], css[`appearance-${appearance}`], className)} {...rest}>
            <div className={cn(css.bg, css.default)} />
            <div className={cn(css.bg, css.error)} />
            <div className={cn(css.bg, css.success)} />

            <div className={css.pageContent}>
                {children}

                <footer className={css.footer}>
                    <a className={css.footerLink} href="#" target="_">
                        {t('support')}
                    </a>

                    <a className={css.footerLink} href="#" target="_">
                        {t('privacyPolicy')}
                    </a>

                    <div className={css.copyright}>© 2022 NTECH LAB</div>
                </footer>
            </div>
        </div>
    );
};

export type ContentProps = HTMLAttributes<HTMLDivElement>;

const Content: React.FC<ContentProps> = ({ children, className, ...rest }) => {
    const isHasComponentInChildren = (children: React.ReactNode, component: React.FC): boolean => {
        let hasIcon = false;

        React.Children.forEach(children, (c) => {
            const element = c as React.ReactElement;
            hasIcon = hasIcon || (element && element?.type === component);
        });
        return hasIcon;
    };

    const [hasIcon, setHasIcon] = useState(isHasComponentInChildren(children, Icon));
    const [hasDesktopRightSideContent, setHasDesktopRightSideContent] = useState(isHasComponentInChildren(children, Icon));

    useEffect(() => {
        setHasIcon(() => isHasComponentInChildren(children, Icon));
        setHasDesktopRightSideContent(() => isHasComponentInChildren(children, DesktopRightSideContent));
    }, [children]);

    return (
        <div
            className={cn(
                css.content,
                { [css.hasIcon]: hasIcon, [css.hasRightSideContent]: hasDesktopRightSideContent },
                className,
            )}
            {...rest}
        >
            {children}
        </div>
    );
};

export type IconProps = HTMLAttributes<HTMLDivElement>;

const Icon: React.FC<IconProps> = ({ children, className, ...rest }) => {
    return (
        <div className={cn(css.icon, className)} {...rest}>
            {children}
        </div>
    );
};

export type TitleProps = HTMLAttributes<HTMLHeadElement>;

const Title: React.FC<TitleProps> = ({ children, className, ...rest }) => {
    return (
        <h3 className={cn(css.title, className)} {...rest}>
            {children}
        </h3>
    );
};

export interface ListProps extends HTMLAttributes<HTMLDivElement> {
    title?: string;
}

const List: React.FC<ListProps> = ({ children, title, className, ...rest }) => {
    return (
        <div className={cn(css.list, className)} {...rest}>
            {title && <div className={css.listTitle}>{title}</div>}
            {children}
        </div>
    );
};

export interface ListItemProps extends HTMLAttributes<HTMLDivElement> {
    bulletIcon?: React.ReactNode;
}

const ListItem: React.FC<ListItemProps> = ({ children, bulletIcon = <BulletIcon />, className, ...rest }) => {
    return (
        <div className={cn(css.listItem, className)} {...rest}>
            <div className={css.bullet}>{bulletIcon}</div>
            {children}
        </div>
    );
};

export type FooterProps = HTMLAttributes<HTMLDivElement>;

const Footer: React.FC<FooterProps> = ({ children, className, ...rest }) => {
    return (
        <div className={cn(css.pageFooter, className)} {...rest}>
            {children}
        </div>
    );
};

export type DesktopRightSideContentProps = HTMLAttributes<HTMLDivElement>;

const DesktopRightSideContent: React.FC<DesktopRightSideContentProps> = ({ children, className, ...rest }) => {
    return (
        <div className={cn(css.rightSideContent, className)} {...rest}>
            {children}
        </div>
    );
};

export default Object.assign(Page, { Content, Icon, Title, List, ListItem, Footer, DesktopRightSideContent });
