import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import Main from 'pages/Main';
import Settings from 'pages/Settings';
import CreateProfile from 'pages/Profile/Create';
import ServerSettings from 'pages/ServerSettings';
import DeleteUser from 'pages/DeleteUser';
import Identification from 'pages/Identification';
import useWindowInnerHeight from 'libs/useWindowInnerHeight';
import Verification from 'pages/Verification';
import * as routes from 'routes';

import css from './styles.module.scss';
import cn from 'classnames';

export const AppLoaderContext = React.createContext({
    showLoader: false,
    setLoader: (value: boolean) => console.log(value),
});

const App: React.FC = () => {
    const innerHeight = useWindowInnerHeight();
    const { push } = useHistory();
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const setLoader = (value: boolean) => setShowLoader(value);

    useEffect(() => {
        push(routes.main());
    }, []);

    return (
        <AppLoaderContext.Provider value={{ showLoader, setLoader }}>
            <div className={css.app} style={{ minHeight: `${innerHeight}px` }}>
                <div className={cn(css.loaderContainer, { [css.show]: showLoader })}>
                    <div className={css.loaderWrapper}>
                        <div className={css.loader} />
                    </div>
                </div>

                <Switch>
                    <Route exact path="/" component={Main} />
                    <Route path={routes.settings()} component={Settings} />
                    <Route path={routes.serverSettings()} component={ServerSettings} />
                    <Route path={routes.deleteUser()} component={DeleteUser} />
                    <Route path={routes.deleteUser()} component={ServerSettings} />
                    <Route path={routes.createProfile()} component={CreateProfile} />
                    <Route path={routes.identification()} component={Identification} />
                    <Route path={routes.verification()} component={Verification} />
                </Switch>
            </div>
        </AppLoaderContext.Provider>
    );
};

export default App;
