import React, { createRef, useEffect, useRef } from 'react';
import Camera from 'libs/camera/Camera';
import Header from 'components/Header';
import Page from 'components/Page';
import Button from 'components/Button';
import { ReactComponent as TakePhotoIcon } from 'assets/icons/take-photo.svg';
import { DEFAULT_SELFIE_VIDEO_DURATION } from 'consts';
import css from './styles.module.scss';
import useScreenType from 'libs/useScreenType';
import { useTranslation } from 'react-i18next';

export interface Props {
    back?: () => void;
    complete: (video: Blob | null) => void;
    title?: string;
    buttonTitle?: string;
    buttonIcon?: React.ReactNode;
    children?: React.ReactNode;
    selfieMovieDuration?: number;
}

const Selfie: React.FC<Props> = ({
    back,
    complete,
    children,
    buttonTitle,
    title,
    buttonIcon = <TakePhotoIcon />,
    selfieMovieDuration = DEFAULT_SELFIE_VIDEO_DURATION,
}) => {
    const cameraContainerRef = createRef<HTMLDivElement>();
    const cameraInstance = useRef<Camera>();
    const [screenType] = useScreenType();
    const { t } = useTranslation();

    buttonTitle = buttonTitle ?? t('takePhoto');
    title = title ?? t('takeSelfie');

    useEffect(() => {
        if (cameraContainerRef.current && !cameraInstance.current) {
            cameraInstance.current = new Camera(cameraContainerRef.current);
        }

        return () => {
            cameraInstance.current?.destroy();
        };
    }, []);

    const takeVideo = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.currentTarget.disabled = true;

        cameraInstance.current
            ?.recordVideo(selfieMovieDuration)
            ?.then((blob) => {
                console.log(blob);
                complete(blob);
            })
            .catch((e) => console.log(e))
            .finally(() => {
                if (event.currentTarget) event.currentTarget.disabled = false;
            });
    };

    return (
        <Page>
            <Header className={css.header} backClick={back}>
                {screenType === 'mobile' && <h4 className={css.title}>{title}</h4>}
            </Header>

            <Page.Content className={css.content}>
                {screenType === 'desktop' && <Page.Title>{title}</Page.Title>}

                <Page.DesktopRightSideContent className={css.photoDesktopSidebar}>
                    <div ref={cameraContainerRef} className={css.camera} />
                    <div className={css.frame} />
                </Page.DesktopRightSideContent>

                {children}

                <Button className={css.button} appearance="violet-fill" icon={buttonIcon} onClick={takeVideo}>
                    {buttonTitle}
                </Button>
            </Page.Content>
        </Page>
    );
};

export default Selfie;
