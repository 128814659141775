import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as LogoNtechLabIcon } from 'assets/icons/logo-ntechlab.svg';
import { ReactComponent as EllipsisIcon } from 'assets/icons/ellipsis.svg';
import faceImage from 'assets/images/face.png';
import faceImageBig from 'assets/images/face-2x.png';
import Button from 'components/Button';
import Page from 'components/Page';
import css from './styles.module.scss';
import cn from 'classnames';
import * as routes from 'routes';

const Main: React.FC = () => {
    const { push } = useHistory();
    const { t } = useTranslation();

    const toSettings = () => push(routes.settings());

    return (
        <Page className={css.page} appearance="main">
            <img
                srcSet={`${faceImage},
                    ${faceImageBig} 2x`}
                className={css.bgImage}
                src={faceImage}
                width={607}
                height={669}
                alt="Background image"
            />

            <Page.Content className={css.content}>
                <div className={css.logos}>
                    <a href="#">
                        <LogoIcon width={56} height={52} />
                    </a>

                    <a href="#">
                        <LogoNtechLabIcon width={101} height={41} />
                    </a>
                </div>

                <h2 className={css.title}>
                    {t('welcomeToFindFaceLiveness')} <span className={css.tag}>DEMO</span>
                </h2>

                <div className={cn(css.text, 'text-normal')}>{t('toGetStartedMMessage')}</div>

                <Button className={css.button} onClick={toSettings} icon={<EllipsisIcon width={29} height={29} />}>
                    {t('enterSettings')}
                </Button>
            </Page.Content>
        </Page>
    );
};

export default Main;
