import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'components/Page';
import Header from 'components/Header';
import cn from 'classnames';
import Button from 'components/Button';
import Selfie from 'components/Selfie';
import AdaptiveIcons from 'components/AdaptiveIcons';
import selfieImageSrc from './selfie.svg';
import { ReactComponent as BulletArrowIcon } from 'assets/icons/bullet-arrow.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/repeat.svg';
import { readBlobAsByteArray } from 'libs';
import { ReactComponent as MarkIcon } from 'assets/icons/mark.svg';
import RestAPI from 'libs/RestAPI';
import { SERVER_ERROR_TYPE, getServerErrorMessage, ServerErrorMessage } from 'libs/serverErrorMessages';
import css from './styles.module.scss';
import uniqid from 'uniqid';
import useAppLoader from 'libs/useAppLoader';
import isServerErrorWithCode from 'libs/isServerErrorWithCode';

export interface Props {
    backClick: () => void;
    complete: () => void;
}

type Stage = 'info' | 'photo' | 'error' | 'error-compare';

const PhotoFace: React.FC<Props> = ({ complete, backClick }) => {
    const [stage, setStage] = useState<Stage>();
    const [errorType, setErrorType] = useState<SERVER_ERROR_TYPE>(SERVER_ERROR_TYPE.UNKNOWN_ERROR);

    const toPhotoStage = () => setStage('photo');
    const toInfoStage = () => setStage('info');

    const toErrorStage = (errorType: SERVER_ERROR_TYPE) => {
        setErrorType(errorType);
        setStage('error');
    };
    const [, setAppLoader] = useAppLoader();

    const movieSuccess = async (movie: Uint8Array) => {
        try {
            setAppLoader(true);
            const { ext } = { ext: 'webm' };
            const result = await RestAPI.onboardingAddSelfieMovie(movie, uniqid('selfie-', `.${ext}`));
            setAppLoader(false);

            if (result.code === 'Ok') complete();
            else {
                console.log('add selfie error', result);

                if (result.code === 'DifferentFaces') {
                    toErrorStage(SERVER_ERROR_TYPE.DIFFERENT_FACES);
                    return;
                }

                if (result.code === 'BadVideo') {
                    const errorMessage =
                        SERVER_ERROR_TYPE[result.details as keyof typeof SERVER_ERROR_TYPE] ?? SERVER_ERROR_TYPE.UNKNOWN_ERROR;
                    return toErrorStage(errorMessage);
                }

                toErrorStage(SERVER_ERROR_TYPE.UNKNOWN_ERROR);
            }
        } catch (e) {
            console.log(e);
            setAppLoader(false);

            if (isServerErrorWithCode(e)) {
                if (e.code == 'network_connection')
                    return toErrorStage(SERVER_ERROR_TYPE.NETWORK_ERROR)

                if (e.code == 'connection_timeout')
                    return toErrorStage(SERVER_ERROR_TYPE.TIMEOUT_ERROR)
            }

            toErrorStage(SERVER_ERROR_TYPE.UNKNOWN_ERROR);
        }
    };

    if (stage === 'photo') return <Photo backClick={toInfoStage} movieSuccess={movieSuccess} />;

    if (stage === 'error')
        return (
            <Error backClick={toInfoStage} repeatAgainClick={toPhotoStage} errorMessage={getServerErrorMessage(errorType)} />
        );

    return <Info backClick={backClick} startClick={toPhotoStage} />;
};

type InfoProps = {
    backClick: () => void;
    startClick: () => void;
};

const Info: React.FC<InfoProps> = ({ backClick, startClick }) => {
    const { t } = useTranslation();

    return (
        <Page>
            <Header backClick={backClick} title={t('firstRun')} />

            <Page.Content className={css.infoContent}>
                <h4 className={css.infoTitle}>{t('addSelfie')}</h4>
                <div className={cn(css.infoText, 'text-normal')}>{t('createSelfie')}</div>

                <Page.DesktopRightSideContent className={css.desktopSidebar}>
                    <img className={css.infoImage} src={selfieImageSrc} alt={t('createSelfie')} width={398} height={421} />
                </Page.DesktopRightSideContent>

                <Button className={css.startButton} appearance="violet-fill" onClick={startClick}>
                    {t('start')}
                </Button>
            </Page.Content>
        </Page>
    );
};

type ErrorProps = {
    backClick: () => void;
    repeatAgainClick: () => void;
    errorMessage: ServerErrorMessage;
};

const Error: React.FC<ErrorProps> = ({ backClick, repeatAgainClick, errorMessage: { title, points } }) => {
    const { t } = useTranslation();

    return (
        <Page type="error">
            <Header backClick={backClick} title={t('error')} />

            <Page.Content className={css.errorContent}>
                <Page.Icon>
                    <AdaptiveIcons name={'cross'} />
                </Page.Icon>

                <Page.Title>{title}</Page.Title>

                <Page.List title={t('itCanBe')}>
                    {points.map((point, index) => (
                        <Page.ListItem bulletIcon={<BulletArrowIcon />} key={index}>
                            {point}
                        </Page.ListItem>
                    ))}
                </Page.List>

                <Page.Footer>
                    <Button
                        className={css.errorButton}
                        icon={<RepeatIcon />}
                        appearance="white-fill"
                        onClick={repeatAgainClick}
                    >
                        {t('repeatAgain')}
                    </Button>
                </Page.Footer>
            </Page.Content>
        </Page>
    );
};

type PhotoProps = {
    backClick: () => void;
    movieSuccess: (file: Uint8Array) => void;
};

const Photo: React.FC<PhotoProps> = ({ backClick, movieSuccess }) => {
    const { t } = useTranslation();
    const photoOptions = [t('takePhotoYourFace_option_1'), t('takePhotoYourFace_option_2')];

    const takeMovie = async (movie: Blob | null) => {
        if (movie) {
            const file = await readBlobAsByteArray(movie);
            movieSuccess(file);
        }
    };

    return (
        <Selfie back={backClick} complete={takeMovie}>
            <Page.List className={css.list}>
                {photoOptions.map((i, index) => (
                    <Page.ListItem bulletIcon={<MarkIcon width={29} height={29} />} key={index}>
                        {i}
                    </Page.ListItem>
                ))}
            </Page.List>
        </Selfie>
    );
};

export default PhotoFace;
