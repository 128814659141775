import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as IdentificationIcon } from 'assets/icons/identification.svg';
import { ReactComponent as VerificationIcon } from 'assets/icons/verification.svg';
import { ReactComponent as NewProfileIcon } from 'assets/icons/new-profile.svg';
import { ReactComponent as GearIcon } from 'assets/icons/gear.svg';
import faceImageBig from 'assets/images/face-2x.png';
import faceImage from 'assets/images/face.png';
import Button from 'components/Button';
import Onboarding from 'pages/Onboarding';
import Page from 'components/Page';
import * as routes from 'routes';
import css from './styles.module.scss';
import { useAppSelector } from 'store/hooks';
import { selectServerHost, selectServerLogin, selectServerPassword } from 'App/slice';

const Main: React.FC = () => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const toSettings = () => push(routes.settings());
    const toCreateProfile = () => push(routes.createProfile());
    const toIdentification = () => push(routes.identification());
    const toVerification = () => push(routes.verification());

    return (
        <Page className={css.page} appearance="main">
            <img
                srcSet={`${faceImage},
                    ${faceImageBig} 2x`}
                className={css.bgImage}
                src={faceImage}
                width={607}
                height={669}
                alt="background image"
            />

            <Page.Content className={css.content}>
                <LogoIcon />

                <Button
                    appearance="white-transparent"
                    className={css.settingButton}
                    dimension="m"
                    displayAsRound
                    onClick={toSettings}
                    icon={<GearIcon />}
                />

                <h1 className={css.title}>{t('livenessDemo')}</h1>

                <ul className={css.buttons}>
                    <li className={css.buttonItem}>
                        <Button
                            className={css.button}
                            dimension="l"
                            textAlign="left"
                            icon={<IdentificationIcon />}
                            onClick={toIdentification}
                        >
                            <div className={css.buttonTitle}>{t('identification')}</div>
                            <div className={css.buttonDescription}>{t('findProfileByFace')}</div>
                        </Button>
                    </li>

                    <li className={css.buttonItem}>
                        <Button
                            className={css.button}
                            dimension="l"
                            textAlign="left"
                            icon={<VerificationIcon />}
                            onClick={toVerification}
                        >
                            <div className={css.buttonTitle}>{t('verification')}</div>
                            <div className={css.buttonDescription}>{t('authByFace')}</div>
                        </Button>
                    </li>

                    <li className={css.buttonItem}>
                        <Button
                            className={css.button}
                            dimension="l"
                            textAlign="left"
                            icon={<NewProfileIcon />}
                            onClick={toCreateProfile}
                        >
                            <div className={css.buttonTitle}>{t('newProfile')}</div>
                            <div className={css.buttonDescription}>{t('addingNewUser')}</div>
                        </Button>
                    </li>
                </ul>
            </Page.Content>
        </Page>
    );
};

const MainWrapper: React.FC = () => {
    const host = useAppSelector(selectServerHost);
    const login = useAppSelector(selectServerLogin);
    const password = useAppSelector(selectServerPassword);

    if (host && login && password) return <Main />;
    return <Onboarding />;
};

export default MainWrapper;
